import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

const PostPreview = ({ post }) => {
  return (
    <article className="postPreview">
      <Link
        to={`/${post.slug}`}
        className="linkToImage"
        title={`/${post.slug}`}
        aria-label={`/${post.slug}`}
      >
        <Image
          className="postPreview__Image"
          alt={post.imageMultiple[0].fixed.description}
          fixed={post.imageMultiple[0].fixed}
          key={post.id}
        />
      </Link>
      <h3 className="postPreviewTitle">
        <Link
          to={`/${post.slug}`}
          style={{
            textDecoration: 'none',
            fontSize: '2rem',
            color: ' $color-3',
          }}
        >
          {post.title}
        </Link>
      </h3>
    </article>
  );
};

export default PostPreview;
