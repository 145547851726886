import { graphql, useStaticQuery } from 'gatsby';

const useContentful = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogpost(limit: 6, sort: { fields: [date], order: DESC }) {
        nodes {
          id
          title
          date
          body {
            body
          }
          slug
          tags
          imageMultiple {
            title
            description
            fixed(width: 350, height: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  `);

  return data.allContentfulBlogpost.nodes.map(post => ({
    id: post.id,
    title: post.title,
    body: post.body.body,
    slug: post.slug,
    tags: post.tags,
    imageMultiple: post.imageMultiple,
  }));
};

export default useContentful;
