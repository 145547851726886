import React from 'react';
import HeroSlider, { Slide } from 'hero-slider';

const Hero = (props) => {
  const slideText = [
    'PRESENTER',
    'SASSY NORTHERNER',
    'LIVE SPECIALIST',
    'WILDLIFE ENTHUSIAST',
    'ARTS BROADCASTER',
    'TRUSTED VOICE',
    'TV & RADIO',
    'RELATABLE',
  ];
  return (
    <HeroSlider
      slidingAnimation="left_to_right"
      orientation="horizontal"
      initialSlide={1}
      shouldLazyLoad={true}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.33)',
      }}
      settings={{
        slidingDuration: 250,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 4000,
        height: '60vh',
      }}
    >
      {props.images.map((image, index) => (
        <Slide
          background={{
            backgroundImage: image.fixed.srcWebp,
            backgroundAttachment: 'fixed',
            alt: image.fixed.src,
          }}
          key={image.fixed.src}
        >
          <div className="heroSlide__TextForImages">{slideText[index]}</div>
        </Slide>
      ))}
    </HeroSlider>
  );
};

export default Hero;
