/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import useContentful from '../hooks/use-contentful';
import useContentfulSmall from '../hooks/use-contentfulSmall';
import PostPreview from '../components/post-preview';
import useContentfulBackground from '../hooks/use-contentfulBackground';
import useContentfulBackgroundMobile from '../hooks/use-contentfulBackgroundMobile';
import useContentfulBackgroundWide from '../hooks/use-contentfulBackgroundWide';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
// import Insta from '../components/insta';
import BackgroundImage from 'gatsby-background-image';

import Hero from '../components/hero';
import HeroMobile from '../components/heroMobile';
import HeroWide from '../components/heroWide';

export default ({ data }) => {
  const breakpoints = useBreakpoint();
  const contentful = useContentful();
  const contentfulSmall = useContentfulSmall();
  const contentfulBackground = useContentfulBackground();
  const contentfulBackgroundMobile = useContentfulBackgroundMobile();
  const contentfulBackgroundWide = useContentfulBackgroundWide();

  const [homeImages, setHomeImages] = useState(
    data.allContentfulLindseyBackgroundImagesApple.edges[0].node.images[0]
      .fluid,
  );
  const [slideText, setSlideText] = useState('PRESENTER');
  const [homeImageCounter, setHomeImageCounter] = useState(0);

  const slideTextArray = [
    'PRESENTER',
    'SASSY NORTHERNER',
    'LIVE SPECIALIST',
    'WILDLIFE ENTHUSIAST',
    'ARTS BROADCASTER',
    'TRUSTED VOICE',
    'TV & RADIO',
    'RELATABLE',
  ];

  setTimeout(() => {
    if (
      homeImageCounter >=
      data.allContentfulLindseyBackgroundImagesApple.edges[0].node.images.length
    ) {
      setHomeImageCounter(0);
      setSlideText(slideTextArray[0]);
      setHomeImages(
        data.allContentfulLindseyBackgroundImagesApple.edges[0].node.images[0]
          .fluid,
      );
    } else {
      setHomeImages(
        data.allContentfulLindseyBackgroundImagesApple.edges[0].node.images[
          homeImageCounter
        ].fluid,
      );
      setSlideText(slideTextArray[homeImageCounter]);
      setHomeImageCounter(homeImageCounter + 1);
    }
  }, 3000);

  const [isIOS, setIsIOS] = useState(true);
  const [isSafari, setIsSafari] = useState(true);

  useEffect(() => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    if (isSafari === true) {
      setIsSafari(true);
    } else {
      setIsSafari(false);
    }

    function iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
    let appleTest = iOS();
    // let appleTest =
    //   /iPad|iPhone|iPod/.test(navigator.platform) ||
    //   (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (appleTest === true) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  return isIOS || isSafari ? (
    <div className="indexGridParent">
      <div className="homePage__imageApple">
        <BackgroundImage fluid={homeImages} className="heroImageApple">
          <h2 className="slideTextApple">{slideText}</h2>
        </BackgroundImage>
      </div>
      <div className="blogsGridAreaApple">
        <h2 className="titleBlogsApple">
          <Link className="latestNewsApple" to="/news">
            Lindsey's latest...
          </Link>
        </h2>
        <div className="postsWrapperApple">
          {contentfulSmall.map((post) => (
            <PostPreview key={post.slug} post={post} />
          ))}
        </div>
      </div>
    
    </div>
  ) : (
    <div className="indexGridParent">
      <div className=".heroGridArea">
        {breakpoints.l ? (
          breakpoints.sm ? (
            <HeroMobile images={contentfulBackgroundMobile[0].images} />
          ) : (
            <Hero images={contentfulBackground[0].images} />
          )
        ) : (
          <HeroWide images={contentfulBackgroundWide[0].images} />
        )}
      </div>
      <div className="blogsGridArea">
        <h2 className="titleBlogs">
          <Link className="latestNews" to="/news">
            Lindsey's latest...
          </Link>
        </h2>
        <div className="postsWrapper">
          {breakpoints.xs
            ? contentfulSmall.map((post) => (
                <PostPreview key={post.slug} post={post} />
              ))
            : contentful.map((post) => (
                <PostPreview key={post.slug} post={post} />
              ))}
        </div>
      </div>
     
    </div>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulLindseyBackgroundImagesApple {
      edges {
        node {
          title

          images {
            title
            description
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
