import { graphql, useStaticQuery } from 'gatsby';

const useContentfulBackground = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLindseyBackgroundImages {
        edges {
          node {
            title

            images {
              title
              description
              fixed(quality: 75, width: 1600) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return data.allContentfulLindseyBackgroundImages.edges.map(
    ({ node: post }) => ({
      images: post.images,
    }),
  );
};

export default useContentfulBackground;
