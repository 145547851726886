import { graphql, useStaticQuery } from 'gatsby';

const useContentfulBackgroundMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLindseyBackgroundImagesMobile {
        edges {
          node {
            lindseybackgroundimagesmobilemedia {
              title
              description
              fixed(quality: 75, width: 1600) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return data.allContentfulLindseyBackgroundImagesMobile.edges.map(
    ({ node: post }) => ({
      images: post.lindseybackgroundimagesmobilemedia,
    }),
  );
};

export default useContentfulBackgroundMobile;
